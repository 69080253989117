<template>
	<div class="anchor" ref="anchor">
		<el-row :gutter="20">
			<el-col :span="6">
				<el-col :span="24" class="bg_fff pr20 pl20 pt20 pb20 tc">
					<img class="brs_8" height="280" width="280" :src="userInfo.avatar" alt="">
					<div class="pt20 pb20">
						<span class="fs20 fw_b pr20">{{ userInfo.username }}</span>
						<i @click="editAnchor" class="cr_589 fs24 fw_b el-icon-edit-outline"></i>
					</div>
					<div class="flex_c cr_999">
						<span class="cr_ff0">{{ userInfo.fans }}</span>
						<span class="pt10 pb10">粉丝</span>
						<span>直播平台: 淘宝</span>
					</div>
				</el-col>
				<el-col :span="24" class="bg_fff pr20 pl20 pt20 pb20 mt20 flex_c f_jc_c f_ai_c">
					<span class="pt10 pb20">所属机构</span>
<!--					<div class="org">-->
<!--						<img width="200" height="200" src="https://filenew.lxshuju.com/618121/20201118/b41049894f843f361a529ffa1af1f151.jpg" alt="">-->
<!--						<span>鲲致千程</span>-->
<!--					</div>-->
				</el-col>
			</el-col>
			<el-col :span="18" class="">
				<el-col :span="24" class="sel mb20 bg_fff pr20 pl20 pt20 pb20">
					<div class="pb10">服务领域：{{ userInfo.ly }}</div>
					<div class="pb10">自我介绍：</div>
					<span class="mt30" v-html="userInfo.per_memo"></span>
				</el-col>
				<el-col class="sel bg_fff pr20 pl20 pt20 pb20" :span="24">
					<span>更多介绍</span>
					<div class="mt30" v-html="userInfo.qd_memo"></div>
				</el-col>
			</el-col>
		</el-row>
		<e-dialog width="800" title="编辑" ref="dialogRef" v-on:confirm="confirm">
			<e-form ref="formRef" :formConfList="formConfListItem">
				<template #1>
					<el-upload
						class="avatar-uploader"
						:action="uploadUrl"
						:show-file-list="false"
						:data="data"
						:on-success="handleAvatarSuccess">
						<img v-if="avatar" :src="avatar" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
				</template>
				<template #qd_memo>
					<editor ref="editorRef" />
				</template>
			</e-form>
		</e-dialog>
	</div>
</template>

<script>
	import {formConfListItem} from '@data/anchor'
	import editor from '../../components/editor.vue'
	export default {
		components: { editor },
		mounted() {
			this.$parent.$el.setAttribute("class", "el-main main bg")
		},
		destroyed() {
			this.$parent.$el.setAttribute("class", "el-main main")
		},
		data() {
			return {
				formConfListItem,
				userInfo: {
					avatar: "",
					username: "",
					mobile: "",
					qd_memo: "",
					ly: ""
				},
				origin_url: '',
				uploadUrl: '',
				data: {},
				avatar: ''
			}
		},
		methods: {
			editAnchor(){
				let that = this
				this.$refs.dialogRef.handleOpen()
				// 更新dom
				//ref在这段方法中可以调到
				this.$nextTick(()=>{
					that.$refs.formRef.formData = this.userInfo
					that.$refs.formRef.formData.username = this.userInfo.username
					that.$refs.formRef.formData.ly = this.userInfo.ly
					that.$refs.formRef.formData.per_memo = this.userInfo.per_memo
					that.$refs.formRef.formData.bq = this.userInfo.bq
					that.$refs.formRef.formData.pt = this.userInfo.pt
					that.avatar = this.userInfo.avatar

					that.$refs.formRef.formData.qq = this.userInfo.qq
					that.$refs.formRef.formData.mobile = this.userInfo.mobile
					that.$refs.formRef.formData.email = this.userInfo.email
					that.$refs.formRef.formData.rate = this.userInfo.rate
					that.$refs.formRef.formData.service = this.userInfo.service
					that.$refs.formRef.formData.bzj = this.userInfo.bzj
					that.$refs.editorRef.content = this.userInfo.qd_memo
					console.log(that.$refs.formRef.formData)

				});
			},
			confirm(){
				let that = this
				let formData = that.$refs.formRef.formData
				this.post('?server=update.e.user.account.info',{
					username:formData.username,
					mobile:formData.mobile,
					qq:formData.qq,
					email:formData.email,
					memo: that.$refs.editorRef.content,
					per_memo: formData.per_memo,
					bq: formData.bq,
					rate: formData.rate,
					service: formData.service,
					bzj: formData.bzj,
					ly: formData.ly,
					cid: this.cat,
					avatar_url:this.origin_url}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message.success(response.data.msg);
					that.$refs.dialogRef.handleClose()
					that.GetAuUserInfo()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},
			handleAvatarSuccess(response){
				if (response.code === 4003) {
					this.$message.error('您没有权限操作');

					return false;
				}
				if(response.code == 0){
					this.$message.error(response.msg);
					return
				}
				this.avatar = response.data
				this.$message.success(response.msg);
				this.avatar = response.data.url
				this.origin_url = response.data.origin_url
			},
			GetAuUserInfo(){
				let that = this
				this.get('?server=live.anchor.info.get',{}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					// console.log(response)
					that.userInfo = response.data
					// console.log(that.$refs.formRef)
					// that.$refs.formRef.formData.username = response.data.username
					// that.$refs.formRef.formData.service = response.data.service
					// that.$refs.formRef.formData.bzj = response.data.bzj
					that.cat = response.data.cid
					// that.$refs.formRef.formData.rate = response.data.rate
					// that.$refs.formRef.formData.pt = response.data.pt
					// that.$refs.editorRef.content = response.data.qd_memo
					that.origin_url = response.data.origin_url
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},
		},
		created() {
			this.$nextTick(() => {
				let token = localStorage.getItem('token')
				this.data = {"auth_token": token};
				this.uploadUrl = this.appConfig.api_request_url+'?server=upload.file'
				this.GetAuUserInfo()
			});
		},
	}
</script>

<style lang="scss" scoped>
.anchor {
	background-color: #f4f3f9 !important;
}
.sel {
	min-height: 250px;
}
.org {
	position: relative;
	span {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		line-height: 40px;
		font-weight: bolder;
		text-align: center;
		color: #fff;
		background-color: rgba($color: #000000, $alpha: .2);
	}
}
</style>

<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>